require('./bootstrap');

$(function() {
    $('div.alert').delay(30000).slideUp(500);
    $('ul.alert').delay(30000).slideUp(500);
    $('.confirm-action').on('click', function (e) {
        return confirm("Are you sure you want to do this?");
    });
});

